import { Box, Button, makeStyles, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState, Fragment, useMemo } from 'react';
import SectionContentFeaturedList from '../../components/section-content-featured-list';
import AnimatedCard from '../../components/card-image-title-description/animated-card';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import CustomGridItem from '../../components/custom-grid/custom-grid-item';
import Layout from '../../components/layout';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import SEO from '../../components/seo';
import CustomGrid from '../../components/custom-grid/custom-grid';
import SectionDescriptionInnerHTML from '../../components/section-description-innerhtml';
import ScrollbarAlwaysOn from '../../components/scrollbar/scrollbar-always-on';
import CarouselStrip from '../../components/carousel-strip/carousel-strip';
import colors from '../../constants/colors';
import { useCustomGridBreakpoints } from '../../components/custom-grid/useCustomGridBreakpoints';

const Page_2021 = ({ pageContext }) => {
  const [speakers, setSpeakers] = useState([]);
  const [partnerCategories, setPartnerCategories] = useState();
  const [digitalSeries, setDigitalSeries] = useState([]);
  const [carousels, setCarousels] = useState([]);
  const [news, setNews] = useState([]);
  const [albums, setAlbums] = useState();
  const [isSpeakersShownAll, setIsSpeakersShownAll] = useState(false);
  const { isDownXs, isDownSm, isDownMd, isDownLg } = useCustomGridBreakpoints();
  const classes = useStyles();

  const resetPageData = useCallback(async () => {
    const { speakers, partners, digitalSeries, news, albums, carousels } = pageContext;
    setSpeakers(orderData(getPageYearData(speakers)));
    setDigitalSeries(filterPublishedData(getPageYearData(digitalSeries)).slice(0, 6));
    setNews(filterPublishedData(getPageYearData(news)));
    setAlbums(getPageYearData(albums).slice(0, 8));
    setCarousels(getPageYearData(carousels));
    const yearPartnerCategories = partners.find((category) => {
      return category.year === YEAR;
    });
    setPartnerCategories(yearPartnerCategories.partnerCategories);
  }, [pageContext]);

  const newsSectionData = useMemo(() => {
    return news.map((news) => ({ ...news, file: [news.file] }));
  }, [news]);

  const toggleViewAllSpeakers = useCallback(() => {
    setIsSpeakersShownAll((value) => !value);
  }, []);

  const speakersSectionData = useMemo(() => {
    if (isSpeakersShownAll) {
      return speakers;
    }
    if (isDownXs) {
      return speakers.slice(0, 3);
    }
    if (isDownSm) {
      return speakers.slice(0, 6);
    }
    if (isDownMd) {
      return speakers.slice(0, 9);
    }
    if (isDownLg) {
      return speakers.slice(0, 12);
    }
    return speakers.slice(0, 12);
  }, [isSpeakersShownAll, speakers, isDownXs, isDownSm, isDownMd, isDownLg]);

  useEffect(() => {
    resetPageData();
  }, [resetPageData]);

  return (
    <Layout>
      <SEO lang='en' title='#GMIS2021' />
      <Grid container>
        {carousels.length > 0 && (
          <Grid item xs={12}>
            <CarouselStrip
              theVideos={carousels}
              oldEvent={true}
              title='Rewiring Societies: Repurposing Digitalisation for Prosperity'
            />
          </Grid>
        )}
        <Grid xs={12} item id='watch-live-stream'>
          <SectionDescriptionInnerHTML
            download={false}
            brochure={false}
            muted
            videoControls
            left
            image={false}
            videoThumbNail='pittsburgh'
            url='https://videos.gmisummit.com/hls/gmis-2021-day-1-wrap-up/stream.m3u8'
            title='GMIS2021 Theme'
            paragraphText={`
<p>#GMIS2021 emphasised on the importance of Data Intelligence and Connectivity, highlighting the evolving integration of humans and machines, reinventing businesses, repurposing capabilities, and rewiring societies. The programme also covered the core technologies that are central to this transformation and addressed key themes surrounding Artificial Intelligence, the Internet of Things, Cloud Computing, and the fifth generation of wireless network technology (5G).</p>
<p class="${classes.contentBold}">Theme Pillars</p>
<ul class="${classes.contentParagraph} ${classes.contentList}">
  <li>Human 2 Human</li>
  <li>Human 2 Machine</li>
  <li>Machine 2 Machine</li>
</ul>
<p>In addition to the conference, #GMIS2021 also ran the UAE’s first ‘MAKE IT IN THE EMIRATES’ exhibition showcasing the latest innovations and solutions in the field of technology and industry.</p>
`}
            // ctaText2='Download #GMIS2021 Agenda'
            // ctaLink2='/pdfs/GMIS-Agenda-DEC2021-Final-web.pdf'
            ctaText='Download the #GMIS2021 Outcomes Report'
            ctaLink='/pdfs/GMIS-2021-Outcomes-book-web.pdf'
            ctaLinkExternal
          />
        </Grid>
        <Grid item xs={12}>
          {partnerCategories && partnerCategories.length > 0 && (
            <SectionContainerLayoutWithFilter
              title='GMIS2021 PARTNERS'
              isViewAll
              viewAllText='view all'
              baseLink='/partners'>
              <ScrollbarAlwaysOn width='100%' height='100%' showArrows>
                <Box display='flex'>
                  {partnerCategories.map((_category) => {
                    if (_category) {
                      return (
                        _category.partner &&
                        _category.partner.length > 0 && (
                          <Fragment key={_category.code}>
                            <Box width={150} mx={1} mb={2}>
                              <img
                                style={{
                                  border: '2px solid #a4c627',
                                }}
                                width='150px'
                                src={
                                  _category.file &&
                                  _category.file.code &&
                                  `${process.env.GATSBY_CMS_API_URL}/static/${_category.file.code}`
                                }
                                alt='category'
                              />
                            </Box>
                            {_category &&
                              _category.partner &&
                              _category.partner.length > 0 &&
                              _category.partner.map((_partner) => {
                                return (
                                  <Box key={_partner.code} width={150} mx={1} mb={2}>
                                    <img
                                      width='150px'
                                      src={
                                        _partner.file &&
                                        _partner.file.code &&
                                        `${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`
                                      }
                                      alt='category'
                                    />
                                  </Box>
                                );
                              })}
                          </Fragment>
                        )
                      );
                    }
                  })}
                </Box>
              </ScrollbarAlwaysOn>
            </SectionContainerLayoutWithFilter>
          )}
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter
            title='GMIS2021 SPEAKERS'
            isViewAll
            viewAllText='View All'
            baseLink='/speakers/2021'>
            <>
              <CustomGrid>
                {speakersSectionData.map((speaker, i) => (
                  <CustomGridItem key={`${speaker.code}${i}`}>
                    <AnimatedCard
                      bio={speaker.bio ? speaker.bio : null}
                      image={speaker.file.code}
                      title={
                        speaker.salutationId === 7 || speaker.salutation.name === 'NULL'
                          ? speaker.firstName + ' ' + speaker.lastName
                          : speaker.salutation.name + ' ' + speaker.firstName + ' ' + speaker.lastName
                      }
                      body={
                        speaker.organization && speaker.organization.length > 1
                          ? speaker.designation + ', ' + speaker.organization
                          : speaker.designation
                      }
                    />
                  </CustomGridItem>
                ))}
              </CustomGrid>
              {!isSpeakersShownAll && speakers.length > speakersSectionData.length && (
                <Box mt={3}>
                  <Button fullWidth variant='contained' color='primary' onClick={toggleViewAllSpeakers}>
                    VIEW MORE SPEAKERS
                  </Button>
                </Box>
              )}
            </>
          </SectionContainerLayoutWithFilter>
        </Grid>

        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter
            title='GMIS2021 GALLERY'
            isViewAll
            viewAllText='View All'
            baseLink='/galleries'>
            <Grid container spacing={3}>
              {albums ? (
                albums.map((_album, index) => (
                  <Grid key={index} item xs={12} sm={6} md={3}>
                    <Box mb={12} width='100%'>
                      <CardImageTitleDescription
                        isImgTag
                        image={_album.files.find((_file) => _file.type !== 'video/mp4').code}
                        title={moment(_album.date).format('DD MMM YYYY')}
                        body={_album.title}
                        ctaText={_album.albumType === 'videos' ? 'View Video Library' : 'View Album'}
                        readMore={`/galleries/${_album.year}/${_album.urlSlug}`}
                      />
                    </Box>
                  </Grid>
                ))
              ) : (
                <p>Loading</p>
              )}
            </Grid>
          </SectionContainerLayoutWithFilter>
        </Grid>
        <Grid item xs={12}>
          {newsSectionData.length > 0 ? (
            <Grid item xs={12}>
              <SectionContainerLayoutWithFilter title='GMIS2021 NEWS' isViewAll viewAllText='View all' baseLink='/news'>
                <SectionContentFeaturedList
                  primaryMediaType='image'
                  secondaryMediaType='image'
                  apiData
                  isNews
                  linkText='Read more'
                  data={newsSectionData}
                />
              </SectionContainerLayoutWithFilter>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter title='GMIS2021 DIGITAL SERIES' baseLink='/digital-series' isViewAll>
            <Grid item container xs={12} spacing={3}>
              {digitalSeries.map((_digitalSeries, index) => (
                <Grid key={_digitalSeries.code} item xs={12} sm={6} lg={4}>
                  <CardImageTitleDescription
                    isImgTag
                    image={
                      _digitalSeries.file[0].type !== 'video/mp4'
                        ? _digitalSeries.file[0].code
                        : _digitalSeries.file[1].code
                    }
                    title={_digitalSeries.title}
                    body={_digitalSeries.description}
                    readMore={`/digital-series/2021/${_digitalSeries.urlSlug}`}
                    lines={6}
                  />
                </Grid>
              ))}
            </Grid>
          </SectionContainerLayoutWithFilter>
        </Grid>
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  contentParagraph: {
    marginBottom: theme.spacing(3),
  },
  contentBold: {
    fontWeight: 'bolder',
  },
  contentList: {
    position: 'relative',
    marginTop: 0,
    marginBottom: theme.spacing(3),

    '& > li::marker': {
      color: colors.primary.dark,
    },
  },
}));

const getPageYearData = (items, filterPublished, order) => {
  return items.filter((item) => Number(item.year) === YEAR);
};

const filterPublishedData = (items) => {
  return items.filter((item) => item.status === 'published');
};

const orderData = (items) => {
  return [...items].sort((a, b) => {
    return a.order - b.order;
  });
};

const YEAR = 2021;

export default Page_2021;
