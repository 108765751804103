import React, { useCallback, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  accordionScrollbar: {
    '& .ps__thumb-y': {
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .ps__thumb-x': {
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    '& .ps__rail-x': {
      opacity: 0.6,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .ps__rail-y': {
      opacity: 0.6,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .ps__rail-x.ps--clicking': {
      backgroundColor: theme.palette.background.default,
    },
    '& .ps__rail-y.ps--clicking': {
      backgroundColor: theme.palette.background.default,
    },
    '& .ps__rail-x:hover > .ps__thumb-x': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .ps__rail-y:hover > .ps__thumb-y': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .ps__rail-y.ps--clicking .ps__thumb-y': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .ps__rail-x.ps--clicking .ps__thumb-x': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const ScrollbarAlwaysOn = ({ maxHeight, width, children, showArrows }) => {
  const [scrollbarRef, setScrollbarRef] = useState();
  const [isPreviousShown, setIsPreviousShown] = useState(false);
  const [isNextShown, setIsNextShown] = useState(false);
  const classes = useStyles();

  const resetButtonShown = useCallback(() => {
    if (scrollbarRef) {
      const width = scrollbarRef.getBoundingClientRect().width;
      const isStart = scrollbarRef.scrollLeft === 0;
      const isEnd = scrollbarRef.scrollWidth - Math.round(scrollbarRef.scrollLeft + width) <= 1;

      setIsPreviousShown(!isStart);
      setIsNextShown(!isEnd);
    }
  }, [scrollbarRef]);

  const handlePrevious = useCallback(() => {
    if (scrollbarRef) {
      const scrollDistance = scrollbarRef.getBoundingClientRect().width;
      scrollbarRef.scrollLeft -= scrollDistance;
    }
  }, [scrollbarRef]);

  const handleNext = useCallback(() => {
    if (scrollbarRef) {
      const scrollDistance = scrollbarRef.getBoundingClientRect().width;
      scrollbarRef.scrollLeft += scrollDistance;
    }
  }, [scrollbarRef]);

  const handleScroll = useCallback(() => {
    resetButtonShown();
  }, [resetButtonShown]);

  useEffect(() => {
    resetButtonShown();
  }, [resetButtonShown]);

  return (
    <Box display='flex' alignItems='center'>
      {showArrows && (
        <IconButton color='primary' disabled={!isPreviousShown} onClick={handlePrevious}>
          <ArrowBackIcon />
        </IconButton>
      )}
      {maxHeight ? (
        <PerfectScrollbar
          onScroll={handleScroll}
          containerRef={setScrollbarRef}
          className={classes.accordionScrollbar}
          style={{ maxHeight: maxHeight }}>
          {children}
        </PerfectScrollbar>
      ) : width ? (
        <PerfectScrollbar
          onScroll={handleScroll}
          containerRef={setScrollbarRef}
          className={classes.accordionScrollbar}
          style={{ width: width }}>
          {children}
        </PerfectScrollbar>
      ) : maxHeight && width ? (
        <PerfectScrollbar
          onScroll={handleScroll}
          containerRef={setScrollbarRef}
          className={classes.accordionScrollbar}
          style={{ maxHeight: maxHeight, width: width, clear: 'both' }}>
          {children}
        </PerfectScrollbar>
      ) : null}
      {showArrows && (
        <IconButton variant='text' disabled={!isNextShown} color='primary' onClick={handleNext}>
          <ArrowForwardIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ScrollbarAlwaysOn;
