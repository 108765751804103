import React, { useRef, useEffect, useMemo } from 'react';
import {
  Button,
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().required(),
});

export const BrochureDownloadModal = ({ open, onClose, link, returnLink }) => {
  const formRef = useRef();
  const classes = useStyles();
  const formik = useFormik({
    onSubmit: () => {
      if (formRef.current) {
        formRef.current.submit();
      }
    },
    validationSchema,
    validateOnMount: true,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      /** Company name */
      '00N5A00000LPeUN': '',
      title: '',
    },
  });

  const downloadButton = useMemo(() => {
    const buttonNode = (
      <Button variant='contained' color='primary' disabled={!formik.isValid} onClick={formik.submitForm}>
        Download Brochure
      </Button>
    );

    if (!formik.isValid) {
      return buttonNode;
    }

    return (
      <a target='_blank' href={link} rel='noreferrer'>
        {buttonNode}
      </a>
    );
  }, [formik.isValid, link, formik.submitForm]);

  useEffect(() => {
    formik.resetForm();
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Please enter your details to download brochure.</DialogTitle>
      <DialogContent>
        <form
          ref={formRef}
          action='https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
          method='POST'>
          <input type='hidden' name='oid' value='00D5A0000016Qjw' />
          <input type='hidden' name='retURL' value={returnLink} />
          {process.env.NODE_ENV === 'development' && (
            <>
              <input type='hidden' name='debug' value='1' />
              <input type='hidden' name='debugEmail' value='shahbaz@eyesomex.com' />
            </>
          )}
          <TextField
            className={classes.textFieldLabelSpacing}
            label='First Name'
            name='first_name'
            fullWidth
            required
            onBlur={() => console.log('blur')}
            error={formik.touched.first_name && formik.errors.first_name}
            {...formik.getFieldProps('first_name')}
            autoComplete={'off'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <Typography variant='body1'>*</Typography>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={classes.textFieldLabelSpacing}
            label='Last Name'
            name='last_name'
            fullWidth
            required
            autoComplete={'off'}
            error={formik.touched.last_name && formik.errors.last_name}
            {...formik.getFieldProps('last_name')}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <Typography variant='body1'>*</Typography>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={classes.textFieldLabelSpacing}
            label='Email'
            name='email'
            required
            fullWidth
            type='email'
            autoComplete={'off'}
            error={formik.touched.email && formik.errors.email}
            {...formik.getFieldProps('email')}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <Typography variant='body1'>*</Typography>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={classes.textFieldLabelSpacing}
            label='Company Name'
            name='00N5A00000LPeUN'
            fullWidth
            error={formik.touched['00N5A00000LPeUN'] && formik.errors['00N5A00000LPeUN']}
            {...formik.getFieldProps('00N5A00000LPeUN')}
            autoComplete={'off'}
          />
          <TextField
            className={classes.textFieldLabelSpacing}
            label='Title'
            name='title'
            fullWidth
            error={formik.touched.title && formik.errors.title}
            {...formik.getFieldProps('title')}
            autoComplete={'off'}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Box mt={5} mb={3} mx={2}>
          {downloadButton}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  textFieldLabelSpacing: {
    '& .MuiFormLabel-root': {
      marginLeft: '10px',
    },
  },
}));
