import React, { useCallback, useState } from 'react';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import VideoContainer from '../video-contanier/video-container';
import ImageContainer from '../image-container';
import SectionContainerLayout from '../section-container-layout/section-container-layout';
import Clamp from 'react-multiline-clamp';
import Fade from 'react-reveal/Fade';
import CustomLink from '../custom-link';
import { BrochureDownloadModal } from '../brochure-download-modal/BrochureDownloadModal';

export const TheParagraph = (props) => {
  const [isBrochureOpen, setIsBrochureOpen] = useState(false);

  const toggleBrochure = useCallback(() => setIsBrochureOpen((open) => !open), []);

  return (
    <>
      <Typography color='textPrimary' variant='h6' gutterBottom>
        {props.paragraphTitle}
      </Typography>
      {props.clamp ? (
        <Clamp lines={props.lines}>
          <Typography color='textSecondary' variant='body1' className='clamp'>
            {props.text}
          </Typography>
        </Clamp>
      ) : (
        <Typography
          color='textSecondary'
          variant='body1'
          component='div'
          dangerouslySetInnerHTML={{ __html: props.text }}></Typography>
      )}

      {props.list && props.list.length !== 0 && (
        <Box clone p={2}>
          <ul>
            {props.list.map((item, idx) => {
              return (
                <li key={idx}>
                  <Box py={1}>
                    <Typography variant='body1' color='textPrimary'>
                      {item}
                    </Typography>
                  </Box>
                </li>
              );
            })}
          </ul>
        </Box>
      )}
      {props.brochure && (
        <Box mt={5}>
          <Fade bottom>
            <Button
              variant='contained'
              color='primary'
              onClick={toggleBrochure}
              style={{
                marginRight: '12px',
              }}>
              Download Brochure
            </Button>
          </Fade>
          <BrochureDownloadModal
            open={isBrochureOpen}
            onClose={toggleBrochure}
            link='/pdfs/GMISAmerica2022.pdf'
            returnLink={typeof window !== 'undefined' ? window.location.href : 'https://gmisummit.com'}
          />
        </Box>
      )}
      <Grid container spacing={1} justify='space-between'>
        <Grid item xs={12}>
          {props.ctaLink && (
            <Box my={5}>
              <Fade bottom>
                <CustomLink
                  type={8}
                  linkUrl={props.ctaLink}
                  linkText={props.ctaText}
                  external={props.ctaLinkExternal}
                />
              </Fade>
            </Box>
          )}
        </Grid>
        <Grid item xs={7}>
          {props.ctaLink2 && (
            <Box my={2}>
              <Fade bottom>
                <CustomLink
                  type={8}
                  linkUrl={props.ctaLink2}
                  linkText={props.ctaText2}
                  external={props.ctaLinkExternal}
                />
              </Fade>
            </Box>
          )}
        </Grid>
      </Grid>

      {props.download && (
        <Box>
          <Typography>Download GMIS2021 APP</Typography>
          <Box display='flex' mt={2}>
            <Box width='120px' display='initial' pr={2}>
              <a
                target='_blank'
                href='http://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1590753842&mt=8'>
                <ImageContainer filename='apple' altText='#GMIS2021 apple' />
              </a>
            </Box>
            <Box width='120px'>
              <a target='_blank' href='http://play.google.com/store/apps/details?id=mobile.app172ZSaAcEC'>
                <ImageContainer filename='android' altText='#GMIS2021 android' />
              </a>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default function SectionDescriptionInnerHTML(props) {
  return (
    <SectionContainerLayout title={props.title}>
      {props.left ? (
        <Grid container spacing={3} justify='space-between'>
          <Box order={{ xs: 2, md: 1 }} clone>
            <Grid item md={6} xs={12}>
              <TheParagraph
                ctaFile={props.ctaFile}
                ctaText={props.ctaText}
                ctaLink={props.ctaLink}
                ctaLinkExternal={props.ctaLinkExternal}
                ctaText2={props.ctaText2}
                ctaLink2={props.ctaLink2}
                text={props.paragraphText}
                paragraphTitle={props.paragraphTitle}
                clamp={props.clamp}
                lines={props.lines}
                list={props.dataList}
                download={props.download}
                brochure={props.brochure}
              />
            </Grid>
          </Box>
          <Box order={{ xs: 1, md: 2 }} clone>
            <Grid item md={6} xs={12}>
              {!props.image ? (
                <VideoContainer
                  src={props.url}
                  thumbNail={props.videoThumbNail}
                  controls={props.videoControls}
                  callbackFn={props.callbackFn}
                  muted={props.muted}
                />
              ) : (
                <ImageContainer filename={props.filename} altText={props.paragraphTitle || '#GMIS'} />
              )}
            </Grid>
          </Box>
        </Grid>
      ) : (
        <Grid container spacing={3} justify='space-between'>
          <Box order={{ xs: 2, md: 1 }} clone>
            <Grid item md={6} xs={12}>
              {!props.image ? (
                <VideoContainer
                  src={props.url}
                  thumbNail={props.videoThumbNail}
                  controls={props.videoControls}
                  callbackFn={props.callbackFn}
                  muted={props.muted}
                />
              ) : (
                <ImageContainer filename={props.filename} altText={props.paragraphTitle || '#GMIS'} />
              )}
            </Grid>
          </Box>

          <Box order={{ xs: 1, md: 2 }} clone>
            <Grid item md={6} xs={12}>
              <TheParagraph
                ctaFile={props.ctaFile}
                ctaText={props.ctaText}
                ctaLink={props.ctaLink}
                ctaText2={props.ctaText2}
                ctaLink2={props.ctaLink2}
                text={props.paragraphText}
                paragraphTitle={props.paragraphTitle}
                clamp={props.clamp}
                lines={props.lines}
                list={props.dataList}
                download={props.download}
                brochure={props.brochure}
              />
            </Grid>
          </Box>
        </Grid>
      )}
      {props.children}
    </SectionContainerLayout>
  );
}
