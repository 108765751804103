import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPlayer from 'react-player';
import {
  Grid,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  withStyles,
  Typography,
  Card,
  CardActionArea,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './carousel-strip.scss';
import colors from '../../constants/colors';
// import AOS from 'aos';
// import '../../styles/sass/aos.css';

const useStyles = (theme) => ({
  speakerName: {
    background:
      theme.palette.type === 'dark'
        ? `${colors.primary.dark}${colors.transparency.typoghraphy}`
        : `${colors.primary.light}${colors.transparency.typoghraphy}`,
    color: theme.palette.type === 'dark' ? `${colors.primary.light}` : `${colors.white}`,
    padding: theme.spacing(1),
  },
  cornerCardBorder: {
    border: '5px solid',
    borderColor: `${theme.palette.background.default} !important`,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      transition: 'all 0.5s !important',
      borderColor: `${theme.palette.primary.main} !important`,
      '&::before': {
        backgroundColor: theme.palette.background.default,
      },
      '&::after': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const REFARRAY = [];
const RANDOMNUM = [2, 4];
const CarouselStrip = (props) => {
  let hoverTimeOut = null;
  let removeHoverTimeOut = null;
  const [mutedVid, setMutedVid] = useState(true);
  const [open, setOpen] = useState(false);
  const [fullScreenVideo, setFullScreenVideo] = useState({
    src: '',
    name: '',
  });
  const [theVideos, setTheVideos] = useState([]);

  // const [isDataLoaded, setIsDataLoaded] = useState(false);

  // useEffect(() => {
  //   // AOS.init();
  //   if (props.theVideos && props.theVideos.length !== 0) {
  //     const commingVideos = props.theVideos.map((video, idx) => {
  //       return {
  //         id: `vid${idx}`,
  //         name: video.title,
  //         url:
  //           video.file[0].type === 'video/mp4'
  //             ? `${process.env.GATSBY_HLS_URL}${video.file[0].code}/stream.m3u8`
  //             : `${process.env.GATSBY_HLS_URL}${video.file[1].code}/stream.m3u8`,
  //         startTime: video.startTime,
  //         poster:
  //           video.video.file[0].type !== 'video/mp4'
  //             ? `${process.env.GATSBY_CMS_API_URL}/static/${video.file[0].code}`
  //             : `${process.env.GATSBY_CMS_API_URL}/static/${video.file[1].code}`,
  //         auto: false,
  //       };
  //       return video;
  //     });
  //     setTheVideos(commingVideos);
  //   }
  // }, []);

  useEffect(() => {
    if (props.theVideos && props.theVideos.length !== 0) {
      let _videoArray = [];

      props.theVideos.map((video, idx) => {
        _videoArray.push({
          id: `vid${idx}`,
          name: video.title,
          url:
            video.file[0].type === 'video/mp4'
              ? `${process.env.GATSBY_HLS_URL}${video.file[0].code}/stream.m3u8`
              : `${process.env.GATSBY_HLS_URL}${video.file[1].code}/stream.m3u8`,
          startTime: video.startTime,
          poster:
            video.file[0].type !== 'video/mp4'
              ? `${process.env.GATSBY_CMS_API_URL}/static/${video.file[0].code}`
              : `${process.env.GATSBY_CMS_API_URL}/static/${video.file[1].code}`,
          auto: false,
        });
      });

      setTheVideos(_videoArray);
    }
  }, []);

  const playHoverHandler = (index, startTime) => {
    try {
      const _allStatesAutoPlay = theVideos;
      _allStatesAutoPlay[index].auto = true;
      hoverTimeOut = setTimeout(() => {
        REFARRAY[index].seekTo(startTime, 'seconds');

        setTheVideos(_allStatesAutoPlay);
        setMutedVid(false);
        document.getElementById('vid' + index).style.opacity = '1';
        document.getElementById('imagePoster' + index).style.display = 'none';
      }, 300);
    } catch (err) {
      console.log('catch play', err);
    }
  };

  const loadVideoHandler = (index) => {
    try {
      clearTimeout(hoverTimeOut);
      const _allStatesAutoPlay = theVideos;
      _allStatesAutoPlay[index].auto = false;
      removeHoverTimeOut = setTimeout(() => {
        document.getElementById('vid' + index).style.opacity = '0';
        setTheVideos(_allStatesAutoPlay);
        setMutedVid(true);
        document.getElementById('imagePoster' + index).style.display = 'block';
      }, 100);
    } catch (err) {
      console.log('catch pause', err);
    }
  };

  const clickOpenVideoHandler = (name, src) => {
    setMutedVid(false);
    setOpen(true);
    setFullScreenVideo({
      src: src,
      name: name,
    });
  };

  const closeVideoHandler = () => {
    setMutedVid(true);
    setOpen(false);
    setFullScreenVideo({
      src: '',
      name: '',
    });
  };
  const { classes } = props;

  return (
    // <Box clone className='carousel-container'>
    // isDataLoaded ? (
    <>
      <Grid container>
        {/* <Grid item xs={12}>
          <Box my={2}>
            {!props.oldEvent ? (
              <Typography align='center' variant='h4'>
                #GMIS2021 is coming to Expo 2020 Dubai
                </Typography>
            ) : (
              <Typography align='center' variant='h4'>
              {props.title}
              </Typography>
              )}
          </Box>
        </Grid> */}
        <Grid item xs={12}>
          <Box id='slider-container-scroll-bottom' className='slider-container'>
            {theVideos &&
              theVideos.length !== 0 &&
              theVideos.map((vid, index) => {
                return (
                  <Box
                    key={index}
                    p={2}
                    position='relative'
                    className={[
                      `sliderVideoPlayer`,
                      theVideos.length === 1 ? 'sliderVideoPlayer-oneVideo' : 'sliderVideoPlayer-2-more',
                    ].join(' ')}
                    width={1 / theVideos.length}
                    top={{ xs: RANDOMNUM[index % 2] + 'rem' }}
                    onClick={() => clickOpenVideoHandler(vid.name, vid.url)}>
                    <Card className='card' elevation={0}>
                      <Box
                        className={[classes.cornerCardBorder, 'cornerCardBorder'].join(' ')}
                        onMouseEnter={() => playHoverHandler(index, vid.startTime)}
                        onMouseLeave={() => loadVideoHandler(index)}>
                        <Box className='borderGlow'></Box>
                        <CardActionArea className='zIndex-1'>
                          <Box m={0} clone key={index} className={['remove-focus', 'corners'].join(' ')}>
                            <Box id={'box' + index}>
                              <ReactPlayer
                                playing={vid.auto}
                                id={vid.id}
                                ref={(accordionContent) => (REFARRAY[index] = accordionContent)}
                                className='videoPlayer'
                                muted={mutedVid}
                                url={vid.url}
                                height='100%'
                              />
                              <img
                                id={`imagePoster` + index}
                                className='imagePoster'
                                src={vid.poster}
                                controls={false}
                                alt='thumbnail'
                              />
                              <Typography
                                className={[classes.speakerName, 'text-uppercase speakerName'].join(' ')}
                                variant='caption'>
                                {vid.name}
                              </Typography>
                            </Box>
                          </Box>
                        </CardActionArea>
                      </Box>
                    </Card>
                  </Box>
                );
              })}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Dialog fullScreen open={open} onClose={closeVideoHandler} TransitionComponent={Transition}>
            <AppBar color='default'>
              <Toolbar>
                <IconButton edge='start' color='inherit' onClick={closeVideoHandler} aria-label='close'>
                  <CloseIcon />
                </IconButton>
                <Typography className='text-uppercase' color='primary' variant='h6'>
                  {fullScreenVideo.name}
                </Typography>
              </Toolbar>
            </AppBar>

            <ReactPlayer
              controls
              playing={true}
              id='fullVideo'
              className='videoPlayerFullScreen controller'
              muted={false}
              url={fullScreenVideo.src}
              width='100%'
              height='auto'
            />
          </Dialog>
        </Grid>
      </Grid>
    </>
    // ) : null
  );
};

export default withStyles(useStyles)(CarouselStrip);
