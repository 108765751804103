import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const useCustomGridBreakpoints = () => {
  const theme = useTheme();
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  return {
    isUpXl,
    isDownLg,
    isDownMd,
    isDownSm,
    isDownXs,
  };
};
